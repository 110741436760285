/**
 * Basic styling
 */
@use "sass:color";
@use "sass:list";

* {
    box-sizing: border-box;
}

body {
    background: url('/assets/jsj.svg') $background-color no-repeat center fixed;
    margin: 0;
    padding: 0;
    font: $base-font-weight list.slash($base-font-size, $base-line-height) $base-font-family;
    color: $text-color;
    -webkit-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern" 1;
    -moz-font-feature-settings: "kern" 1;
    -o-font-feature-settings: "kern" 1;
    font-feature-settings: "kern" 1;
    font-kerning: normal;
}

a {
    color: #30508E;

    &:hover {
        color: color.adjust(#30508E, $lightness: 20%);
    }
}
