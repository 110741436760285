@charset "utf-8";
@use "sass:color";
@use "sass:math";

$base-em-size: 16;

// Our variables
$base-font-family: 'Open Sans', sans-serif;
$base-font-size: $base-em-size * 1px;
$base-font-weight: 400;
$small-font-size: $base-font-size * 0.875;
$base-line-height: 1.5;

@function em($pixels) {
  @return #{math.div($pixels, $base-em-size)}em;
}

$spacing-unit: 30px;

$text-color: #111;
$background-color: #f1f3f6;
$brand-color: #203152;

//$grey-color:       #828282;
//$grey-color-light: color.adjust($grey-color, $lightness: 40%);
//$grey-color-dark:  color.adjust($grey-color, $lightness: -25%);

// Width of the content area
$content-width: em(1024);

//$on-palm:          600px;
//$on-laptop:        800px;
$on-mobile-l: em(425);
$on-tablet: em(768);
$on-laptop: em(1024);

// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
  @media screen and (min-width: $device) {
    @content;
  }
}

// Import partials from `sass_dir` (defaults to `_sass`)
@import "normalize",
"base",
"base",
"layout",
"syntax-highlighting";
