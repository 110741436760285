/**
 * Syntax highlighting styles
 */
%vertical-rhythm {
  margin-bottom: $spacing-unit * 0.5;
}

.highlight {
  background: #f8f8f8;
  @extend %vertical-rhythm;
  max-width: 100%;
  padding: 0rem;

  .hll {
    background-color: #ffffcc
  }
  .c {
    color: #408080;
    font-style: italic
  }
  .err {
    border: 1px solid #FF0000
  }
  .k {
    color: #008000;
    font-weight: bold
  }
  .o {
    color: #666666
  }
  .ch {
    color: #408080;
    font-style: italic
  }
  .cm {
    color: #408080;
    font-style: italic
  }
  .cp {
    color: #BC7A00
  }
  .cpf {
    color: #408080;
    font-style: italic
  }
  .c1 {
    color: #408080;
    font-style: italic
  }
  .cs {
    color: #408080;
    font-style: italic
  }
  .gd {
    color: #A00000
  }
  .ge {
    font-style: italic
  }
  .gr {
    color: #FF0000
  }
  .gh {
    color: #000080;
    font-weight: bold
  }
  .gi {
    color: #00A000
  }
  .go {
    color: #888888
  }
  .gp {
    color: #000080;
    font-weight: bold
  }
  .gs {
    font-weight: bold
  }
  .gu {
    color: #800080;
    font-weight: bold
  }
  .gt {
    color: #0044DD
  }
  .kc {
    color: #008000;
    font-weight: bold
  }
  .kd {
    color: #008000;
    font-weight: bold
  }
  .kn {
    color: #008000;
    font-weight: bold
  }
  .kp {
    color: #008000
  }
  .kr {
    color: #008000;
    font-weight: bold
  }
  .kt {
    color: #B00040
  }
  .m {
    color: #666666
  }
  .s {
    color: #BA2121
  }
  .na {
    color: #7D9029
  }
  .nb {
    color: #008000
  }
  .nc {
    color: #0000FF;
    font-weight: bold
  }
  .no {
    color: #880000
  }
  .nd {
    color: #AA22FF
  }
  .ni {
    color: #999999;
    font-weight: bold
  }
  .ne {
    color: #D2413A;
    font-weight: bold
  }
  .nf {
    color: #0000FF
  }
  .nl {
    color: #A0A000
  }
  .nn {
    color: #0000FF;
    font-weight: bold
  }
  .nt {
    color: #008000;
    font-weight: bold
  }
  .nv {
    color: #19177C
  }
  .ow {
    color: #AA22FF;
    font-weight: bold
  }
  .w {
    color: #bbbbbb
  }
  .mb {
    color: #666666
  }
  .mf {
    color: #666666
  }
  .mh {
    color: #666666
  }
  .mi {
    color: #666666
  }
  .mo {
    color: #666666
  }
  .sa {
    color: #BA2121
  }
  .sb {
    color: #BA2121
  }
  .sc {
    color: #BA2121
  }
  .dl {
    color: #BA2121
  }
  .sd {
    color: #BA2121;
    font-style: italic
  }
  .s2 {
    color: #BA2121
  }
  .se {
    color: #BB6622;
    font-weight: bold
  }
  .sh {
    color: #BA2121
  }
  .si {
    color: #BB6688;
    font-weight: bold
  }
  .sx {
    color: #008000
  }
  .sr {
    color: #BB6688
  }
  .s1 {
    color: #BA2121
  }
  .ss {
    color: #19177C
  }
  .bp {
    color: #008000
  }
  .fm {
    color: #0000FF
  }
  .vc {
    color: #19177C
  }
  .vg {
    color: #19177C
  }
  .vi {
    color: #19177C
  }
  .vm {
    color: #19177C
  }
  .il {
    color: #666666
  }
}
